import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import { transactionType, userDataType } from '../../store/reducers/UserSlice';
import logo from '../../assets/img/dark_logo.png';

interface BankStatementProps {
  transactions: transactionType[];
  dateTo: Date | null;
  dateFrom: Date | null;
  userData: userDataType;
}

const styles = StyleSheet.create({
  page: {
    padding: 30,
    fontSize: 10,
    backgroundColor: '#FFFFFF',
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: 20,
  },
  leftSection: {
    width: '25%',
  },
  centerSection: {
    width: '50%',
    textAlign: 'center',
  },
  rightSection: {
    width: '25%',
    textAlign: 'right',
  },
  bankInfo: {
    fontSize: 12,
    marginBottom: 10,
  },
  accountInfo: {
    marginBottom: 20,
  },
  transactionsHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottomWidth: 1,
    borderBottomColor: '#000',
    paddingBottom: 5,
    marginTop: 10,
    fontWeight: 'bold',
  },
  transactionRow: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 5,
    borderBottomWidth: 1,
    borderBottomColor: '#eee',
    textAlign: "center",
  },
  date: {
    width: '10%',
    textAlign: "left",
},
transactionNumber: {
    width: '25%',
    textAlign: "left",
},
type: {
    width: '15%',
    textAlign: "left",
},
direction: {
    width: '10%',
},
amount: {
    width: '10%',
},
ref: {
    width: '10%',
},
balance: {
    width: '10%',
    textAlign: "right",
  },
  status: {
    width: '10%',
    textAlign: "right",
  },
  img: {
    width: 100,
  },
  bankName: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 5,
  },
  textBold: {
    fontWeight: 'bold',
  },
  title: {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 20,
  },
  pageNumber:{
    position: 'absolute',
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center"
  }
});

const ConvertDate = (timestamp: any) => {
  let dateFormat = new Date(timestamp * 1000);
  
  return dateFormat.getDate() +
  "/" + (dateFormat.getMonth() + 1) +
  "/" + dateFormat.getFullYear()
}
const formatDate = (timestamp: any) => {
  return timestamp ? new Date(timestamp).toLocaleDateString('en-GB'): 'N/A'  
};

const BankStatement: React.FC<BankStatementProps> = ({ transactions, dateFrom, dateTo, userData }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.header}>
        <View style={styles.leftSection}>
          <Image src={logo} style={styles.img} />
        </View>

        <View style={styles.centerSection}>
          <Text style={styles.bankName}>GBH Coriolis Bank</Text>
          <Text style={styles.title}>STATEMENT OF ACCOUNT</Text>
        </View>

        <View style={styles.rightSection}>
          <Text style={styles.textBold}>GBH Coriolis Bank</Text>
          <Text>Room 2, 3rd floor, Junction mall</Text>
          <Text>Ngong Road, Nairobi, Kenya</Text>
          <Text>info@gbhcoriolisbank.com</Text>
        </View>
      </View>

      <View style={styles.accountInfo}>
        <Text>Statement Date: {new Date().toISOString().slice(0, 10)}</Text>
        <Text>Period Covered: {formatDate(dateFrom)} to {formatDate(dateTo)}</Text>
        <Text>{userData.firstName} {userData.lastName}</Text>
        <Text>{userData.address}</Text>
      </View>

      <View>
        <View style={styles.transactionsHeader}>
          <Text style={styles.date}>Date</Text>
          <Text style={styles.transactionNumber}>Transaction #</Text>
          <Text style={styles.type}>Type</Text>
          <Text style={styles.direction}>Direction</Text>
          <Text style={styles.amount}>Amount</Text>
          <Text style={styles.ref}>Reference</Text>
          <Text style={styles.balance}>Balance</Text>
          <Text style={styles.status}>Status</Text>
        </View>

        {transactions.map((transaction, index) => (
          <View key={index} style={styles.transactionRow}>
            <Text style={styles.date}>{ConvertDate(transaction.created)}</Text>
            <Text style={styles.transactionNumber}>{transaction.transactionNumber}</Text>
            <Text style={styles.type}>{transaction.transactionType}</Text>
            <Text style={styles.direction}>{transaction.direction}</Text>
            <Text style={styles.amount}>{transaction.amount}</Text>
            <Text style={styles.ref}>{transaction.reference}</Text>
            <Text style={styles.balance}>{transaction.balance}</Text>
            <Text style={styles.status}>{transaction.transactionStatus}</Text>
          </View>
        ))}
      </View>
      <Text 
        style={styles.pageNumber} 
        render={({pageNumber, totalPages})=>`${pageNumber} / ${totalPages}`} 
        fixed />
    </Page>
  </Document>
);

export default BankStatement;
